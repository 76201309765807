import React, { useContext, useState } from "react";

import AdminContext from "../../contexts/AdminContext";

import ConfirmAdminModal from "./ConfirmAdminModal";
import ModalField from "../../components/ModalField";
import InputField from "../../components/InputField";

import { handleType, handleEmailVerified } from "../../utils/FunctionsUtil";

import "../../scss/App.scss";

export default function Profile() {
  const { loggedAdmin } = useContext(AdminContext);

  const [confirmModalOpen, setConfirmModalOpen] = useState();

  const handleButtonClick = () => {
    setConfirmModalOpen(true);
  };

  return (
    <React.Fragment>
      <div className="page-details">
        <form>
          <div className="page-details-first-part">
            <h3> Your profile </h3>
            <InputField
              type="text"
              id="username"
              value={loggedAdmin?.username}
              labelName="Username"
              disabled={true}
            ></InputField>
            <InputField
              type="text"
              id="email"
              value={loggedAdmin?.email}
              labelName="Email"
              disabled={true}
            ></InputField>
            <InputField
              type="text"
              id="type"
              value={handleType(loggedAdmin?.type)}
              labelName="Type"
              disabled={true}
            ></InputField>
            <InputField
              type="text"
              id="emailVerified"
              value={handleEmailVerified(loggedAdmin?.isEmailVerified)}
              labelName="Email verified"
              disabled={true}
            ></InputField>
            <div hidden={loggedAdmin?.isEmailVerified}>
              <button
                type="button"
                className="resolve-button"
                style={{ width: "150px", cursor: "pointer" }}
                onClick={handleButtonClick}
              >
                {" "}
                Verify mail
              </button>
            </div>
            <ModalField
              isOpen={confirmModalOpen}
              component={<ConfirmAdminModal admin={loggedAdmin} />}
              onClick={() => setConfirmModalOpen(false)}
            ></ModalField>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}
