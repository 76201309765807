import React, { useState, useEffect, useCallback, useContext } from "react";
import InputField from "../../components/InputField";
import ButtonField from "../../components/ButtonField";
import AdminContext from "../../contexts/AdminContext";

export default function UserDeleteModal({ user }) {
  const { deleteUser, confirmDeleteUser } = useContext(AdminContext);
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [userId, setUserId] = useState(user.id);
  const [confirmDeleteData, setConfirmDeleteData] = useState({
    userId: user.id,
    code: "",
    password: "",
  });

  useEffect(() => {
    setUserId(user.id);
    setConfirmDeleteData({ ...confirmDeleteData, userId: user.id });
    // eslint-disable-next-line
  }, [user]);

  const handleConfirmDeleteUser = useCallback(async () => {
    const result = await confirmDeleteUser(confirmDeleteData);
    if (result?.status === 200) {
      alert(`You have successfully deleted ${user.nickname}!`);
      window.location = "/users";
    }

    return result;
  }, [confirmDeleteUser, confirmDeleteData, user.nickname]);

  const requestDeleteUser = useCallback(async () => {
    const result = await deleteUser(userId);
    return result;
  }, [deleteUser, userId]);

  if (!user) return <div></div>;

  const handleSubmit = (event) => {
    event.preventDefault();
    handleConfirmDeleteUser();
  };

  const handleResendMail = () => {
    requestDeleteUser();
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
    setConfirmDeleteData({
      ...confirmDeleteData,
      password: event.target.value,
    });
  };
  const handleChangeCode = (event) => {
    event.preventDefault();
    setCode(event.target.value);
    setConfirmDeleteData({ ...confirmDeleteData, code: event.target.value });
  };

  const handleDisableDelete = () => {
    if (!code || !password) return true;
    else return false;
  };

  return (
    <div>
      <h2>Delete user</h2>
      <form onSubmit={handleSubmit}>
        <h3>We've sent a code to your email. Please enter it down below. </h3>
        <ButtonField onClick={handleResendMail} name="Send mail again" />
        <InputField
          type="text"
          value={code}
          onChange={handleChangeCode}
          placeholder="Enter a code"
          labelName="Code"
        />
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={handleChangePassword}
            placeholder="Enter a password"
          />
        </label>
        <input type="submit" value="Delete" disabled={handleDisableDelete()} />
      </form>
    </div>
  );
}
