import React, { createContext, useEffect, useState } from "react";

const AuthenticationContext = createContext();

export const AuthenticationProvider = (props) => {
  const [token, setToken] = useState();

  const saveUserToken = (token) => {
    setToken(token);
    localStorage.setItem("@token", token);
  };

  const removeToken = () => {
    localStorage.removeItem("@token");
    setToken(undefined);
  };

  const getToken = () => {
    const token = localStorage.getItem("@token");

    if (token) setToken(token);
  };

  useEffect(() => {
    if (!token) getToken();
  }, [token]);

  const providerValue = {
    token,
    saveUserToken,
    removeToken,
    getToken,
  };

  return (
    <AuthenticationContext.Provider value={providerValue}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationContext;
