import React from "react";

import { Button } from "@mui/material";
import Modal from "react-modal";

export default function ModalField(props) {
  const { isOpen, component, onClick } = props;

  return (
    <Modal isOpen={isOpen}>
      {component}
      <Button
        onClick={onClick}
        className="closeModalButton"
        style={{ backgroundColor: "#233948", margin: 4, color: "white" }}
      >
        Close modal
      </Button>
    </Modal>
  );
}
