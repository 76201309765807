import React, { useState, useEffect, useContext, useCallback } from "react";

import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import AdminContext from "../../contexts/AdminContext";

import InputField from "../../components/InputField";

import { handleType, handleEmailVerified } from "../../utils/FunctionsUtil";

import "../../scss/App.scss";

export default function SingleAdminPage() {
  const history = useHistory();

  const { deleteAdmin, updateAdmin, getAdmin } = useContext(AdminContext);

  const [admin, setAdmin] = useState();
  const [initialUpdateData, setInitialUpdateData] = useState({
    username: "",
    email: "",
    password: "",
  });

  let { id } = useParams();

  const initializeAdmin = useCallback(async () => {
    const result = await getAdmin(id);
    setInitialUpdateData({
      ...initialUpdateData,
      username: result.username,
      email: result.email,
    });

    return setAdmin(result);
    // eslint-disable-next-line
  }, [getAdmin, id]);

  useEffect(() => {
    initializeAdmin();
  }, [initializeAdmin]);

  const handleChangeUsername = (username) => {
    setAdmin({ ...admin, username });
  };

  const handleChangeEmail = (email) => {
    setAdmin({ ...admin, email });
  };

  const handleChangePassword = (password) => {
    setInitialUpdateData({ ...initialUpdateData, password });
  };



  const handleDeleteAdmin = useCallback(async () => {
    const result = await deleteAdmin(id);
    if (result.status === 200) {
      alert("You have successfully deleted admin!");
      history.push("/admin");
    }
    // eslint-disable-next-line
  }, [deleteAdmin, id]);

  const confirmDeletingAdmin = () => {
    const shouldDeleteAdmin = window.confirm("Are you sure you want to delete this admin?");

    if (shouldDeleteAdmin)
      handleDeleteAdmin();
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    updateAdmin(id, {
      username: admin.username,
      email: admin.email,
      password: initialUpdateData.password,
    });

    alert("You have successfully updated admin data");
    if (initialUpdateData.password) handleChangePassword("");
  };

  const handleDisableChangeAdminData = () => {
    if (
      initialUpdateData.username === admin.username &&
      initialUpdateData.email === admin.email &&
      !initialUpdateData.password
    )
      return true;
    return false;
  };

  return (
    <React.Fragment>
      <div className="page-details">
        <form onSubmit={handleSubmit}>
          <div className="page-details-first-part">
            <h3> Admin data </h3>
            <InputField
              type="text"
              id="username"
              value={admin?.username}
              labelName="Username"
              onChange={(event) => handleChangeUsername(event.target.value)}
            ></InputField>
            <InputField
              type="text"
              id="email"
              value={admin?.email}
              labelName="Email"
              onChange={(event) => handleChangeEmail(event.target.value)}
            ></InputField>
            {admin?.type === 1 && (
              <InputField
                type="text"
                id="password"
                value={initialUpdateData.password}
                labelName="Enter new password"
                onChange={(event) => handleChangePassword(event.target.value)}
              ></InputField>
            )}
            <InputField
              type="text"
              id="type"
              value={handleType(admin?.type)}
              labelName="Type"
              disabled={true}
            ></InputField>
            <InputField
              type="text"
              id="emailVerified"
              value={handleEmailVerified(admin?.isEmailVerified)}
              labelName="Email verified"
              disabled={true}
            ></InputField>
            {admin?.type === 1 && (
              <InputField
                type="submit"
                disabled={handleDisableChangeAdminData()}
                value="Accept changes"
              ></InputField>
            )}
            <button
              type="button"
              hidden={admin?.type !== 1}
              className="resolve-button"
              style={{ width: "100%" }}
              onClick={confirmDeletingAdmin}
            >
              Delete admin
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}
