import React, { useContext, useEffect } from "react";

import { useHistory } from "react-router";
import { DataGrid } from "@mui/x-data-grid";

import ReportContext from "../../contexts/ReportContext";

import {
  handleDate,
  handleReportStatus,
  handleReportReason,
} from "../../utils/FunctionsUtil";

import "../../scss/App.scss";

export default function ReportDataGrid() {
  const { reports, unsolvedReports, getReports, getUnsolvedReports } =
    useContext(ReportContext);
  const history = useHistory();

  const handleRowClick = (report) => {
    const path = "/reports/" + report.id;
    history.push({
      pathname: path,
      state: {
        report: report.row,
      },
    });
  };

  useEffect(() => {
    getReports();
    getUnsolvedReports();
  }, [getReports, getUnsolvedReports]);

  const columns = [
    { field: "id", hide: true, filterable: false },
    {
      field: "name",
      headerName: "Reporter Name",
      headerClassName: "super-app-theme--header",
      width: 180,
      sortable: false,
      valueGetter: (params) =>
        params.row.reporter.firstName + " " + params.row.reporter.lastName,
    },
    {
      field: "reason",
      headerName: "Title",
      headerClassName: "super-app-theme--header",
      width: 320,
      sortable: false,
      valueGetter: (params) => handleReportReason(params.value),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      headerClassName: "super-app-theme--header",
      width: 220,
      valueGetter: (params) => handleDate(params.value),
    },
    {
      field: "isResolved",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 230,
      sortable: false,
      valueGetter: (params) => handleReportStatus(params.value),
    },
  ];

  const columnsUnsolvedReport = [
    { field: "id", hide: true, filterable: false },
    {
      field: "name",
      headerName: "Reporter Name",
      headerClassName: "super-app-theme--header",
      width: 180,
      sortable: false,
      valueGetter: (params) =>
        params.row.reporter.firstName + " " + params.row.reporter.lastName,
    },
    {
      field: "reason",
      headerName: "Title",
      headerClassName: "super-app-theme--header",
      width: 320,
      sortable: false,
      valueGetter: (params) => handleReportReason(params.value),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      headerClassName: "super-app-theme--header",
      width: 220,
      valueGetter: (params) => handleDate(params.value),
    },
    {
      field: "isResolved",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 230,
      sortable: false,
      valueGetter: (params) => handleReportStatus(params.value),
    },
  ];

  return (
    <React.Fragment>
      <div className="header-title">
        <div><strong>Resolved</strong></div>
        <div><strong>Not Resolved</strong></div>
      </div>
      <div style={{ width: "100%", margin: "0", padding: "0" }} className="ban-data-grid">
        <DataGrid
          onRowClick={(report) => handleRowClick(report)}
          rows={reports}
          columns={columns}
          pageSize={14}
          rowsPerPageOptions={[14]}
          disableColumnSelector
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }]
            }
          }}
        />
        <DataGrid
          onRowClick={(report) => handleRowClick(report)}
          rows={unsolvedReports}
          columns={columnsUnsolvedReport}
          pageSize={14}
          rowsPerPageOptions={[14]}
          disableColumnSelector
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }]
            }
          }}

        />
      </div>
    </React.Fragment>
  );
}
