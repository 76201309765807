import React, { useCallback, useContext, useEffect, useState } from "react";

import { useParams } from "react-router";

import AdsContext from "../../contexts/AdsContext";
import TradesContext from "../../contexts/TradesContext";
import UsersContext from "../../contexts/UsersContext";
import BansContext from "../../contexts/BansContext";
import AdminContext from "../../contexts/AdminContext";

import ButtonField from "../../components/ButtonField";
import InputField from "../../components/InputField";
import ModalField from "../../components/ModalField";
import UserAdsDataGrid from "./UserAdsDataGrid";
import UserBanModal from "./UserBanModal";
import UserUnbanModal from "./UserUnbanModal";
import UserDeleteModal from "./UserDeleteModal";
import UserTradesDataGrid from "./UserTradesDataGrid";

import "../../scss/App.scss";

export default function UserPage() {
  const { deleteUser } = useContext(AdminContext);
  const { unbanUser } = useContext(BansContext);
  const { getUser, editUsername } = useContext(UsersContext);
  const { getTradesByUserBuyer, getTradesByUserSeller } =
    useContext(TradesContext);
  const { getAdsByUser } = useContext(AdsContext);

  const [user, setUser] = useState();
  const [tradesForUserBuyer, setTradesForUserBuyer] = useState();
  const [hiddenUnban, setHiddenUnban] = useState(false);
  const [tradesForUserSeller, setTradesForUserSeller] = useState();
  const [adsForUser, setAdsForUser] = useState();
  const [openTradeModal, setOpenTradeModal] = useState(false);
  const [openAdModal, setOpenAdModal] = useState(false);
  const [openBanModal, setOpenBanModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUnbanModal, setOpenUnbanModal] = useState(false);

  let { id } = useParams();

  const initializeUser = useCallback(async () => {
    const result = await getUser(id);
    setHiddenUnban(result.isBanned);
    return setUser(result);
  }, [getUser, id]);

  const initializeTradesForUserBuyer = useCallback(async () => {
    const resultsForBuyer = await getTradesByUserBuyer(id);
    return setTradesForUserBuyer(resultsForBuyer);
  }, [getTradesByUserBuyer, id]);

  const initializeTradesForUserSeller = useCallback(async () => {
    const resultsForSeller = await getTradesByUserSeller(id);
    return setTradesForUserSeller(resultsForSeller);
  }, [getTradesByUserSeller, id]);

  const initializeUserAds = useCallback(async () => {
    const adResults = await getAdsByUser(id);
    return setAdsForUser(adResults);
  }, [getAdsByUser, id]);

  useEffect(() => {
    initializeUser();
  }, [initializeUser]);

  useEffect(() => {
    initializeTradesForUserBuyer();
  }, [initializeTradesForUserBuyer]);

  useEffect(() => {
    initializeTradesForUserSeller();
  }, [initializeTradesForUserSeller]);

  useEffect(() => {
    initializeUserAds();
  }, [initializeUserAds]);

  const handleDate = () => {
    if (user) return new Date(Date.parse(user.createdAt)).toLocaleString();
  };

  const handleAllexxPlus = () => {
    if (user) {
      switch (user.subscriptionType) {
        case 1:
          return "None";
        case 2:
          return "Plus";
        case 3:
          return "Pro";
        default:
          return "Unknown";
      }
    }
  };

  const handleDoneTrades = () => {
    if (user) return user.completedTradesAsBuyer + user.completedTradesAsSeller;
  };

  const handleCurrentTrades = () => {
    if (tradesForUserSeller && tradesForUserBuyer) {
      let counter = 0;
      const totalTrades = tradesForUserSeller.concat(tradesForUserBuyer);

      totalTrades.forEach((trade) => {
        if (trade.status === 4) counter++;
      });

      return counter;
    }
  };

  const handleMoney = (trades) => {
    if (trades) {
      let total = 0;
      trades.forEach((trade) => (total += trade.earned));

      return total.toFixed(2);
    }
  };

  const handleAds = (isActive) => {
    if (adsForUser) {
      let counter = 0;
      adsForUser.forEach((ad) => {
        if (ad.isActive === isActive) counter++;
      });

      return counter;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    editUsername(id, user.nickname);

    alert(`Username changed: ${user.nickname}!`);
  };

  const requestUnbanUser = useCallback(async () => {
    const result = await unbanUser(id);
    return result;
  }, [unbanUser, id]);

  const handleUnbanUser = () => {
    requestUnbanUser();
    setOpenUnbanModal(true);
  };

  const requestDeleteUser = useCallback(async () => {
    const result = await deleteUser(id);
    return result;
  }, [deleteUser, id]);

  const handleDeleteUser = () => {
    requestDeleteUser();
    setOpenDeleteModal(true);
  };

  const handleChangeUsername = (nickname) => {
    setUser({ ...user, nickname });
  };

  return (
    <React.Fragment>
      <div className="profile">
        <img src={user?.avatarUrl} alt="Profile"></img>
        <h3>User ID: {user?.id}</h3>
        {user?.paymentCustomerId && <h3>Customer ID: {user.paymentCustomerId}</h3>}
        {user?.payoutAccountId && <h3>Account ID: {user.payoutAccountId}</h3>}
      </div>

      <div className="page-details">
        <form onSubmit={handleSubmit}>
          <div className="page-details-first-part">
            <InputField
              type="text"
              id="nickname"
              value={user?.nickname}
              labelName="Username"
              onChange={(event) => handleChangeUsername(event.target.value)}
            />

            <InputField
              type="text"
              id="email"
              value={user?.email}
              disabled={true}
              labelName="Email"
            />

            <InputField
              type="text"
              id="firstName"
              value={user?.firstName}
              disabled={true}
              labelName="First name"
            />

            <InputField
              type="text"
              id="lastName"
              value={user?.lastName}
              disabled={true}
              labelName="Last name"
            />

            <InputField
              type="text"
              id="phoneNumber"
              value={user?.areaCode + user?.phoneNumber}
              disabled={true}
              labelName="Phone number"
            />

            <InputField
              type="text"
              id="country"
              value={user?.country}
              disabled={true}
              labelName="Country"
            />

            <InputField
              type="text"
              id="creationDate"
              value={handleDate()}
              disabled={true}
              labelName="Creation date"
            />

            <InputField
              type="text"
              id="allexxPlus"
              value={handleAllexxPlus()}
              disabled={true}
              labelName="Allexx plus"
            />
          </div>

          <div className="page-details-second-part">
            <InputField
              type="text"
              id="positiveRating"
              value={user?.positiveRatingCount}
              disabled={true}
              labelName="Positive rating"
            />
            <InputField
              type="text"
              id="negativeRating"
              value={user?.negativeRatingCount}
              disabled={true}
              labelName="Negative rating"
            />

            <InputField
              type="text"
              id="numberOfDoneTrades"
              value={handleDoneTrades()}
              disabled={true}
              labelName="Number of done trades"
            />

            <InputField
              type="text"
              id="numberOfCurrentTrades"
              value={handleCurrentTrades()}
              disabled={true}
              labelName="Number of current trades"
            />

            <InputField
              type="text"
              id="numberOfDoneAds"
              value={handleAds(true)}
              disabled={true}
              labelName="Number of past ads"
            />

            <InputField
              type="text"
              id="numberOfCurrentAds"
              value={handleAds(false)}
              disabled={true}
              labelName="Number of current ads"
            />

            <InputField
              type="text"
              id="earnedMoney"
              value={handleMoney(tradesForUserSeller)}
              disabled={true}
              labelName="Earned money"
            />

            <InputField
              type="text"
              id="spentMoney"
              value={handleMoney(tradesForUserBuyer)}
              disabled={true}
              labelName="Spent money"
            />
          </div>
          <InputField type="submit" value="Change username" />
        </form>

        <div className="footer">
          <div className="user-lists">
            <ButtonField
              onClick={() => setOpenTradeModal(true)}
              name="List of all trades"
              className="button-field"
            />

            <ButtonField
              onClick={() => setOpenAdModal(true)}
              name="List of all ads"
            />
          </div>

          <ModalField
            isOpen={openTradeModal}
            component={<UserTradesDataGrid />}
            onClick={() => setOpenTradeModal(false)}
          />

          <ModalField
            isOpen={openAdModal}
            component={<UserAdsDataGrid />}
            onClick={() => setOpenAdModal(false)}
          />

          <div className="user-buttons">
            <div hidden={hiddenUnban}>
              <ButtonField onClick={() => setOpenBanModal(true)} name="Ban" />
            </div>
            <ModalField
              isOpen={openBanModal}
              component={<UserBanModal user={user} />}
              onClick={() => setOpenBanModal(false)}
            />
            <div hidden={!hiddenUnban}>
              <ButtonField onClick={handleUnbanUser} name="Unban" />
            </div>

            <ModalField
              isOpen={openUnbanModal}
              component={<UserUnbanModal user={user} />}
              onClick={() => setOpenUnbanModal(false)}
            />
            <ButtonField onClick={handleDeleteUser} name="Delete user" />
            <ModalField
              isOpen={openDeleteModal}
              component={<UserDeleteModal user={user} />}
              onClick={() => setOpenDeleteModal(false)}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
