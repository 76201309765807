import React, { useContext, useState } from "react";

import InputField from "../components/InputField";

import LoginContext from "../contexts/LoginContext";

export default function Login() {
  const { authenticate } = useContext(LoginContext);

  const [loginData, setLoginData] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    authenticate(loginData);
  };

  const handleInputChange = (fieldName, value) => {
    setLoginData({
      ...loginData,
      [fieldName]: value,
    });
  };

  return (
    <div className="login">
      <h3>Back office - Log in</h3>
      <form onSubmit={handleSubmit}>
        <InputField
          labelName="Email"
          type="text"
          id="email"
          onChange={(event) => handleInputChange("email", event.target.value)}
          placeholder="Email"
          value={loginData?.email}
        ></InputField>
        <InputField
          labelName="Password"
          type="password"
          id="password"
          onChange={(event) =>
            handleInputChange("password", event.target.value)
          }
          placeholder="Password"
          value={loginData?.password}
        ></InputField>
        <InputField type="submit" value="Log in"></InputField>
      </form>
    </div>
  );
}
