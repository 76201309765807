import React, { createContext, useContext } from "react";

import axios from "axios";
import config from "../config/config";

import AuthenticationContext from "./AuthenticationContext";

const AxiosContext = createContext();

export const AxiosProvider = (props) => {
  const { token } = useContext(AuthenticationContext);

  const axiosInstance = axios.create({
    baseURL: config.backendUrl,
    headers: {
      authorization: token ? `Bearer ${token}` : undefined,
    },
  });

  const providerValue = {
    axiosInstance,
  };

  return (
    <AxiosContext.Provider value={providerValue}>
      {props.children}
    </AxiosContext.Provider>
  );
};

export default AxiosContext;
