import React from "react";

export default function InputField(props) {
  const { labelName, type, id, value, onChange, disabled, placeholder } = props;

  const formatValueToString = () => {
    if (value === undefined) return "";
    else if (typeof value === "number") return value.toString();

    return value;
  };

  return (
    <div>
      <label>{labelName}</label>
      <input
        type={type}
        id={id}
        value={formatValueToString()}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
      ></input>
    </div>
  );
}
