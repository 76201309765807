import React from "react";

import { Button } from "@mui/material";

export default function ButtonField(props) {
  const { onClick, name } = props;

  return (
    <Button
      style={{ backgroundColor: "#233948", margin: 4, color: "white" }}
      onClick={onClick}
    >
      {name}
    </Button>
  );
}
