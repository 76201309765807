import React, { useContext, useState, useCallback } from "react";
import { useHistory } from "react-router";

import AdminContext from "../../contexts/AdminContext";

import InputField from "../../components/InputField";

import "../../scss/App.scss";

export default function AdminPage() {
  const history = useHistory();

  const { createAdmin } = useContext(AdminContext);

  const [admin, setAdmin] = useState({
    username: "",
    email: "",
    password: "",
  });

  const handleChangeUsername = (username) => {
    setAdmin({ ...admin, username });
  };

  const handleChangeEmail = (email) => {
    setAdmin({ ...admin, email });
  };

  const handleChangePassword = (password) => {
    setAdmin({ ...admin, password });
  };

  const handleCreateAdmin = useCallback(async () => {
    const result = await createAdmin(admin);
    if (!!result) {
      alert("You have successfully created a new admin!");
      history.push("/admin");
    }
    // eslint-disable-next-line
  }, [createAdmin, admin]);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleCreateAdmin();
  };

  const handleDisableCreate = () => {
    if (!admin.email || !admin.username || !admin.password) return true;
    return false;
  };

  return (
    <React.Fragment>
      <div style={{ marginLeft: "33%" }} className="page-details">
        <h2
          style={{
            marginLeft: "20px",
            marginBottom: "40px",
            marginTop: "50px",
          }}
        >
          Create new admin
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="page-details-first-part">
            <InputField
              type="text"
              id="username"
              value={admin?.username}
              labelName="Username"
              onChange={(event) => handleChangeUsername(event.target.value)}
            ></InputField>
            <InputField
              type="text"
              id="email"
              value={admin?.email}
              labelName="Email"
              onChange={(event) => handleChangeEmail(event.target.value)}
            ></InputField>
            <InputField
              type="text"
              id="firstName"
              value={admin?.password}
              labelName="Password"
              onChange={(event) => handleChangePassword(event.target.value)}
            ></InputField>
            <InputField
              disabled={handleDisableCreate()}
              type="submit"
              value="Create admin"
            ></InputField>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}
