import React, { useState, createContext, useContext, useCallback } from "react";

import AxiosContext from "./AxiosContext";

const UsersContext = createContext();

export const UsersProvider = (props) => {
  const { axiosInstance } = useContext(AxiosContext);

  const [users, setUsers] = useState([]);

  const getData = useCallback(async () => {
    const result = await axiosInstance({
      method: "GET",
      url: "/users",
    });

    return setUsers(result.data);
  }, [axiosInstance]);

  async function getUser(id) {
    const result = await axiosInstance({
      method: "GET",
      url: `/users/${id}`,
    });

    return result.data;
  }

  async function editUsername(id, nickname) {
    await axiosInstance({
      method: "PATCH",
      url: `/users/update-nickname/${id}`,
      data: { nickname },
    });

    getData();
  }

  const providerValue = {
    users,
    getData,
    getUser,
    editUsername,
  };

  return (
    <UsersContext.Provider value={providerValue}>
      {props.children}
    </UsersContext.Provider>
  );
};

export default UsersContext;
