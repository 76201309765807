import {
  handleFuelType,
  handleFuelStandard,
  handleStatusOfTrade,
  handleDate,
  handleNickname,
} from "../../utils/FunctionsUtil";

const getTotalPrice = (params) => {
  return params.row.priceByLiter * params.row.quantity;
};

const columnsAllTrade = [
  { field: "id", hide: true, filterable: false },
  {
    field: "buyer",
    headerName: "Buyer",
    headerClassName: "super-app-theme--header",
    width: 228,
    sortable: false,
    valueGetter: (params) => handleNickname(params.row.buyer),
  },
  {
    field: "seller",
    headerName: "Seller",
    headerClassName: "super-app-theme--header",
    width: 230,
    sortable: false,
    valueGetter: (params) => handleNickname(params.row.seller),
  },
  {
    field: "fuelStandard",
    headerName: "Fuel standard",
    headerClassName: "super-app-theme--header",
    width: 200,
    sortable: false,
    valueGetter: (params) => handleFuelStandard(params.value),
  },
  {
    field: "country",
    headerName: "Country",
    headerClassName: "super-app-theme--header",
    width: 200,
    sortable: false,
  },
  {
    field: "priceByLiter",
    headerName: "Price per liter",
    headerClassName: "super-app-theme--header",
    width: 200,
    type: "number",
    sortable: false,
  },
  {
    field: "totalPrice",
    headerName: "Total price",
    headerClassName: "super-app-theme--header",
    width: 200,
    type: "number",
    sortable: false,
    valueGetter: getTotalPrice,
  },
  {
    field: "quantity",
    headerName: "Quantity",
    headerClassName: "super-app-theme--header",
    width: 200,
    type: "number",
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    headerClassName: "super-app-theme--header",
    width: 220,
    sortable: false,
    valueGetter: (params) => handleStatusOfTrade(params.value),
  },
  {
    field: "createdAt",
    headerName: "Creation date",
    headerClassName: "super-app-theme--header",
    width: 240,
    type: "date",
    filterable: false,
    valueFormatter: (params) => handleDate(params.value),
  },
];

const columnsAdTrades = [
  { field: "id", hide: true, filterable: false },
  {
    field: "buyer",
    headerName: "Buyer",
    headerClassName: "super-app-theme--header",
    width: 240,
    sortable: false,
    filterable: false,
    valueFormatter: (params) => handleNickname(params.row.buyer),
  },
  {
    field: "fuelType",
    headerName: "Fuel type",
    headerClassName: "super-app-theme--header",
    width: 240,
    sortable: false,
    valueGetter: (params) => handleFuelType(params.value),
  },
  {
    field: "country",
    headerName: "Country",
    headerClassName: "super-app-theme--header",
    width: 200,
    sortable: false,
  },
  {
    field: "priceByLiter",
    headerName: "Price per liter",
    headerClassName: "super-app-theme--header",
    width: 200,
    type: "number",
    sortable: false,
  },
  {
    field: "totalPrice",
    headerName: "Total price",
    headerClassName: "super-app-theme--header",
    width: 200,
    type: "number",
    sortable: false,
    valueGetter: getTotalPrice,
  },
  {
    field: "quantity",
    headerName: "Quantity",
    headerClassName: "super-app-theme--header",
    width: 200,
    type: "number",
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    headerClassName: "super-app-theme--header",
    width: 240,
    sortable: false,
    valueGetter: (params) => handleStatusOfTrade(params.value),
  },
  {
    field: "createdAt",
    headerName: "Creation date",
    headerClassName: "super-app-theme--header",
    width: 270,
    type: "date",
    filterable: false,
    valueFormatter: (params) => handleDate(params.value),
  },
];

export { getTotalPrice, columnsAllTrade, columnsAdTrades };
