import React, { useCallback, useContext, useState } from "react";

import { useParams, useLocation, useHistory } from "react-router-dom";

import ReportContext from "../../contexts/ReportContext";
import InputField from "../../components/InputField";
import TextArea from "../../components/TextArea";

import {
  handleDate,
  handleReportStatus,
  handleReportReason,
} from "../../utils/FunctionsUtil";

export default function ReportPage() {
  const history = useHistory();

  const { resolveReport } = useContext(ReportContext);

  const [resolveReportData, setResolveReportData] = useState({
    IsResolved: true,
    Resolution: "",
  });
  const { id } = useParams();

  const location = useLocation();

  const handleSolveReport = () => {
    handleResolveReport();
  };

  const handleResolveReport = useCallback(async () => {
    const result = await resolveReport(id, resolveReportData);
    if (!!result) {
      alert("You have successfully resolved this report");
      history.push("/reports");
    }
    // eslint-disable-next-line
  }, [resolveReport, resolveReportData, id]);

  const handleChangeText = (value) => {
    setResolveReportData({ ...resolveReportData, Resolution: value });
  };

  const handleDisableButton = () => {
    if (!resolveReportData.Resolution) return true;
    return false;
  };

  return (
    <React.Fragment>
      <div style={{ marginTop: "100px" }} className="page-details">
        <div style={{ flexDirection: "row", display: "flex" }}>
          <div
            style={{ marginBottom: "40px" }}
            className="page-details-without-form"
          >
            <h3 style={{ marginTop: "10px", marginBottom: "20px" }}>
              {" "}
              Reporter{" "}
            </h3>
            <InputField
              type="text"
              id="name"
              value={
                location?.state?.report?.reporter?.firstName +
                " " +
                location?.state?.report?.reporter?.lastName
              }
              labelName="Name:"
              disabled={true}
            />
            <InputField
              type="text"
              id="phoneNumber"
              value={
                location?.state?.report?.reporter?.areaCode +
                location?.state?.report?.reporter?.phoneNumber
              }
              labelName="Phone number:"
              disabled={true}
            />
            <InputField
              type="text"
              id="email"
              value={location?.state?.report?.reporter?.email}
              labelName="Email:"
              disabled={true}
            />
          </div>

          <div
            style={{ marginBottom: "40px" }}
            className="page-details-without-form"
          >
            <h3 style={{ marginTop: "10px", marginBottom: "20px" }}>
              {" "}
              Reported{" "}
            </h3>
            <InputField
              type="text"
              id="name"
              value={
                location?.state?.report?.reportee?.firstName +
                " " +
                location?.state?.report?.reportee?.lastName
              }
              labelName="Name:"
              disabled={true}
            />
            <InputField
              type="text"
              id="phoneNumber"
              value={
                location?.state?.report?.reportee?.areaCode +
                location?.state?.report?.reportee?.phoneNumber
              }
              labelName="Phone number:"
              disabled={true}
            />
            <InputField
              type="text"
              id="email"
              value={location?.state?.report?.reportee?.email}
              labelName="Email:"
              disabled={true}
            />
          </div>
          <div
            style={{ marginBottom: "40px" }}
            className="page-details-without-form"
          >
            <h3 style={{ marginTop: "10px", marginBottom: "20px" }}>
              {" "}
              Report{" "}
            </h3>
            <InputField
              type="text"
              id="status"
              value={handleReportStatus(location?.state?.report?.isResolved)}
              labelName="Status:"
              disabled={true}
            />
            <InputField
              type="text"
              id="reason"
              value={handleReportReason(location?.state?.report?.reason)}
              labelName="Reason:"
              disabled={true}
            />
            <InputField
              type="text"
              id="description"
              value={location?.state?.report?.comment}
              labelName="Report description::"
              disabled={true}
            />
            <InputField
              type="text"
              id="createdAt"
              value={handleDate(location?.state?.report?.createdAt)}
              labelName="Created at:"
              disabled={true}
            />
            {location?.state?.report?.isResolved && (
              <TextArea
                labelName="Resolution:"
                value={location?.state?.report?.resolution}
                disabled={true}
              />
            )}
          </div>
        </div>

        {!location?.state?.report?.isResolved ? (
          <div
            style={{
              marginTop: "50px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3> Resolve report: </h3>
            <input
              className="report-resolve-input"
              onChange={(e) => handleChangeText(e.target.value)}
            />
            <button
              className="resolve-button"
              onClick={handleSolveReport}
              disabled={handleDisableButton()}
            >
              {" "}
              Resolve
            </button>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
}
