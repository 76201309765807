const handleFuelType = (param) => {
  switch (param) {
    case 1:
      return "Gasoline";
    case 2:
      return "Diesel";
    default:
      return "Unknown";
  }
};

const handleNickname = (param) => {
  return param.nickname;
};

const BanType = {
  PERMANENT: 0,
  TEMPORARY: 1,
};

const BanStatus = {
  UNVERIFIED: 1,
  VERIFIED: 2,
};

const handleBanType = (param) => {
  switch (param) {
    case 1:
      return "In ban process";
    case 2:
      return "Verified ban";
    case 3:
      return "Unbanned";
    case 4:
      return "Automatically unbanned";
    case 5:
      return "Deleted";
    default:
      return "Unknown";
  }
};

const handleFuelStandard = (param) => {
  switch (param) {
    case 1:
      return "E5";
    case 2:
      return "E10";
    case 3:
      return "E85";
    case 4:
      return "B7";
    case 5:
      return "B10";
    case 6:
      return "XTL";
    default:
      return "Unknown";
  }
};

const handleStatusOfTrade = (param) => {
  switch (param) {
    case 1:
      return "New";
    case 2:
      return "Pending";
    case 3:
      return "Seller accepted trade";
    case 4:
      return "Confirmed";
    case 5:
      return "Completed";
    case 6:
      return "Canceled by buyer";
    case 7:
      return "Canceled by seller";
    case 8:
      return "Not concluded";
    default:
      return "Unknown";
  }
};

const handleVehicleType = (param) => {
  switch (param) {
    case 1:
      return "Car";
    case 2:
      return "Motorbike";
    case 3:
      return "Van";
    case 4:
      return "Truck";
    case 5:
      return "Watercraft";
    case 6:
      return "Fishboat";
    case 7:
      return "Sailboat";
    case 8:
      return "Speedboat";
    case 9:
      return "Other";
    default:
      return "Unknown";
  }
};

const handleDate = (param) => {
  return new Date(Date.parse(param)).toLocaleString();
};

const handleTimeFrame = (param) => {
  const timeframes = [];
  for (let i = 0; i < param?.length; i++) {
    const oneTimeframe =
      // eslint-disable-next-line
      (i != 0 ? " " : "") +
      param[i].startHour +
      "h" +
      " - " +
      param[i].endHour +
      "h";
    timeframes.push(oneTimeframe);
  }
  return timeframes;
};

const handlePaymentType = (param) => {
  switch (param) {
    case 0:
      return "None";
    case 1:
      return "Cash";
    case 2:
      return "Card";
    default:
      return "Unknown";
  }
};

const handleType = (param) => {
  switch (param) {
    case 1:
      return "Regular Admin";
    case 2:
      return "Super Admin";
    default:
      return "Unknown";
  }
};

const handleEmailVerified = (param) => {
  if (param) return "Verified";
  return "Not Verified";
};

const handleReportReason = (param) => {
  switch (param) {
    case 1:
      return "User Refuses To Cooperate";
    case 2:
      return "User Didn't Show Up";
    case 3:
      return "User Is Not Following The Contract";
    case 4:
      return "Scamming";
    case 5:
      return "Physical Or Verbal Aggression";
    case 6:
      return "Sexual Harassment";
    case 7:
      return "Dangerous Situation";
    case 8:
      return "System Error";
    default:
      return "Unknown";
  }
};

const handleReportStatus = (param) => {
  if (param) return "Resolved";
  return "Not Resolved";
};

const isAdStatusActive = (ad) => {
  const currentDate = new Date();

  const locationStartDate = new Date(ad.locations[0].startDateTime);

  const locationEndDate =
    ad.locations[0].endDateTime === null
      ? null
      : new Date(ad.locations[0].endDateTime);

  return (
    !ad.isDeleted &&
    ad.availableQuantity >= ad.minimumSellingQuantity &&
    locationStartDate <= currentDate &&
    (locationEndDate === null || locationEndDate > currentDate)
  );
};

const getAdStatus = (param) => {
  return isAdStatusActive(param) ? "Active" : "Passed";
};

export {
  handleFuelType,
  handleNickname,
  handleStatusOfTrade,
  handleDate,
  handleTimeFrame,
  handlePaymentType,
  handleVehicleType,
  handleEmailVerified,
  handleFuelStandard,
  handleType,
  handleBanType,
  BanType,
  BanStatus,
  handleReportStatus,
  handleReportReason,
  getAdStatus as handleAdStatus,
};
