import React, { useContext, useEffect, useState, useCallback } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";

import { handleFuelType, handleDate } from "../../utils/FunctionsUtil";

import AdsContext from "../../contexts/AdsContext";

import "../../scss/App.scss";

export default function UserAdsDataGrid() {
  const { getAdsByUser } = useContext(AdsContext);

  const [adsByUser, setAdsByUser] = useState();

  let { id } = useParams();

  const initializeUserAds = useCallback(async () => {
    const result = await getAdsByUser(id);

    return setAdsByUser(result);
  }, [getAdsByUser, id]);

  useEffect(() => {
    initializeUserAds();
  }, [initializeUserAds]);

  const handleIsActive = (params) => {
    if (params) return "Active";
    else return "Passed";
  };

  const columns = [
    { field: "id", hide: true, filterable: false },
    {
      field: "currentPriceByLiter",
      headerName: "Price per liter",
      headerClassName: "super-app-theme--header",
      width: 250,
      filterable: false,
    },
    {
      field: "totalQuantity",
      headerName: "Total quantity",
      headerClassName: "super-app-theme--header",
      width: 250,
      filterable: false,
    },
    {
      field: "availableQuantity",
      headerName: "Available quantity",
      headerClassName: "super-app-theme--header",
      width: 250,
      filterable: false,
    },
    {
      field: "fuelType",
      headerName: "Fuel type",
      headerClassName: "super-app-theme--header",
      width: 250,
      filterable: false,
      valueGetter: (params) => handleFuelType(params.value),
    },
    {
      field: "createdAt",
      headerName: "Creation date",
      headerClassName: "super-app-theme--header",
      width: 270,
      type: "date",
      filterable: false,
      valueGetter: (params) => handleDate(params.value),
    },
    {
      field: "isActive",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 250,
      filterable: false,
      valueGetter: (params) => handleIsActive(params),
    },
    {
      field: "location #1 (country/city)",
      headerName: "Location",
      headerClassName: "super-app-theme--header",
      width: 270,
      filterable: false,
      valueGetter: (params) =>
        params.row.locations[0].country + ", " + params.row.locations[0].city,
    },
  ];

  const getRows = () => {
    if (adsByUser) return adsByUser;
    else return [];
  };

  return (
    <div className="user-ads-data-grid">
      <h3 className="user-ads-header">User ads</h3>
      <DataGrid
        rows={getRows()}
        columns={columns}
        pageSize={11}
        rowsPerPageOptions={[11]}
        disableColumnSelector
      />
    </div>
  );
}
