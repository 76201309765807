import React, { useCallback, useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import ButtonField from "../../components/ButtonField";
import ModalField from "../../components/ModalField";
import BansContext from "../../contexts/BansContext";
import UserUnbanModal from "../user/UserUnbanModal";
import ConfirmBanModal from "../bans/ConfirmBanModal";
import InputField from "../../components/InputField";

import { handleDate, BanType, BanStatus } from "../../utils/FunctionsUtil";

export default function BanPage() {
  const { getOneBan, resendMail } = useContext(BansContext);
  const [ban, setBan] = useState();
  const [openUnbanModal, setOpenUnbanModal] = useState(false);
  const [confirmBanModalOpen, setConfirmBanModalOpen] = useState(false);
  const { id } = useParams();
  const { unbanUser } = useContext(BansContext);

  const initializeBan = useCallback(async () => {
    const result = await getOneBan(id);
    return setBan(result);
  }, [id, getOneBan]);

  useEffect(() => {
    initializeBan();
  }, [initializeBan]);

  const requestUnbanUser = useCallback(async () => {
    const result = await unbanUser(ban?.user.id);
    return result;
  }, [unbanUser, ban?.user.id]);

  const handleUnbanUser = () => {
    requestUnbanUser();
    setOpenUnbanModal(true);
  };

  const handleConfirmBanUser = () => {
    requestResendMail();
    setConfirmBanModalOpen(true);
  };

  const requestResendMail = useCallback(async () => {
    const result = await resendMail(id);
    return result;
  }, [resendMail, id]);

  return (
    <React.Fragment>
      <div className="page-details">
        <div className="page-details-without-form">
          <h3 style={{ marginTop: "10px", marginBottom: "20px" }}>
            {" "}
            Banned user{" "}
          </h3>
          <InputField
            type="text"
            id="username"
            value={ban?.user.nickname}
            labelName="Username:"
            disabled={true}
          />
          <InputField
            type="text"
            id="phoneNumber"
            value={ban?.user.areaCode + ban?.user.phoneNumber}
            labelName="Phone number:"
            disabled={true}
          />
          <InputField
            type="text"
            id="title"
            value={ban?.title}
            labelName="Title:"
            disabled={true}
          />
          <InputField
            type="text"
            id="description"
            value={ban?.description}
            labelName="Description:"
            disabled={true}
          />
          <InputField
            type="text"
            id="dateOfTheBan"
            value={handleDate(ban?.createdAt)}
            labelName="Date of the ban:"
            disabled={true}
          />
          {ban?.type === BanType.TEMPORARY && (
            <InputField
              type="text"
              id="temporaryBan"
              value={handleDate(ban?.days)}
              labelName="Banned to the date:"
              disabled={true}
            />
          )}
          {ban?.type === BanType.PERMANENT && (
            <InputField
              type="text"
              id="permamentBan"
              value={"Life"}
              labelName="Period of time of the ban:"
              disabled={true}
            />
          )}
        </div>
        <div
          hidden={
            ban?.type !== BanType.TEMPORARY ||
            ban?.status !== BanStatus.VERIFIED
          }
        >
          <ButtonField onClick={handleUnbanUser} name="Unban" />
        </div>
        <ModalField
          isOpen={openUnbanModal}
          component={<UserUnbanModal user={ban?.user} />}
          onClick={() => setOpenUnbanModal(false)}
        />
        <div hidden={ban?.status !== BanStatus.UNVERIFIED}>
          <ButtonField onClick={handleConfirmBanUser} name="Confirm ban" />
        </div>
        <ModalField
          isOpen={confirmBanModalOpen}
          component={<ConfirmBanModal id={ban?.id} />}
          onClick={() => setConfirmBanModalOpen(false)}
        />
      </div>
    </React.Fragment>
  );
}
