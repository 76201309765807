import React, { useContext, useEffect } from "react";

import { useHistory } from "react-router";
import { DataGrid } from "@mui/x-data-grid";

import AdsContext from "../../contexts/AdsContext";

import {
  handleFuelType,
  handleVehicleType,
  handleFuelStandard,
  handleDate,
  handleTimeFrame,
  handleNickname,
  handleAdStatus
} from "../../utils/FunctionsUtil";

import "../../scss/App.scss";

export default function AdDataGrid() {
  const { ads, getAllAds } = useContext(AdsContext);
  const history = useHistory();

  useEffect(() => {
    getAllAds();
  }, [getAllAds]);

  const columns = [
    { field: "id", hide: true, filterable: false },
    {
      field: "seller",
      headerName: "User",
      headerClassName: "super-app-theme--header",
      width: 200,
      sortable: false,
      valueGetter: (params) => handleNickname(params.row.seller),
    },
    {
      field: "fuelType",
      headerName: "Fuel type",
      headerClassName: "super-app-theme--header",
      width: 200,
      sortable: false,
      valueGetter: (params) => handleFuelType(params.value),
    },
    {
      field: "fuelStandard",
      headerName: "Fuel standard",
      headerClassName: "super-app-theme--header",
      width: 170,
      sortable: false,
      valueGetter: (params) => handleFuelStandard(params.value),
    },
    {
      field: "fueledFrom",
      headerName: "Vehicle type",
      headerClassName: "super-app-theme--header",
      width: 200,
      sortable: false,
      valueGetter: (params) => handleVehicleType(params.value),
    },
    {
      field: "location",
      headerName: "Location",
      headerClassName: "super-app-theme--header",
      width: 400,
      sortable: false,
      valueGetter: (params) => params.row.locations[0].address,
    },
    {
      field: "currentPriceByLiter",
      headerName: "Price by liter",
      headerClassName: "super-app-theme--header",
      width: 170,
      type: "number",
      sortable: false,
    },
    {
      field: "totalQuantity",
      headerName: "Total quantity",
      headerClassName: "super-app-theme--header",
      type: "number",
      width: 200,
      sortable: false,
    },
    {
      field: "availableQuantity",
      headerName: "Available quantity",
      headerClassName: "super-app-theme--header",
      type: "number",
      width: 170,
      sortable: false,
    },
    {
      field: "isActive",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 200,
      sortable: false,
      valueGetter: (params) => handleAdStatus(params.row),
    },
    {
      field: "createdAt",
      headerName: "Creation date",
      filterable: false,
      width: 200,
      type: "date",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => handleDate(params.value),
    },
    {
      field: "timeFrame",
      headerName: "Time frames",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        handleTimeFrame(params.row.locations[0].timeFrames),
    },
  ];

  return (
    <React.Fragment>
      <div className="main-data-grid">
        <DataGrid
          onRowClick={(ad) => history.push(`/ads/${ad.id}`)}
          rows={ads}
          columns={columns}
          pageSize={14}
          rowsPerPageOptions={[14]}
          disableColumnSelector
        />
      </div>
    </React.Fragment>
  );
}
