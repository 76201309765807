import React from 'react';
import { Route } from "react-router-dom";
import Login from '../pages/Login';
import { isJwtValid } from "./../utils/JwtUtil"

export default function ProtectedRoute( {component: Component, ...restOfProps } ) {
    return (
        <Route
            {...restOfProps} 
            render = { (props) => 
                isJwtValid() ? <Component {... props} /> : <Login />
            } 
        />
    );
}