import React, { createContext, useCallback, useContext, useState } from "react";

import AxiosContext from "./AxiosContext";

const NGOContext = createContext();

export const NGOProvider = (props) => {
  const { axiosInstance } = useContext(AxiosContext);

  const [projects, setProjects] = useState([]);

  const getData = useCallback(async () => {
    const result = await axiosInstance({
      method: "GET",
      url: "/donation-project",
    });

    return setProjects(result.data);
  }, [axiosInstance]);

  async function patchOffset(data) {
    try {
      const result = await axiosInstance({
        method: "PATCH",
        url: `donation-project/offset`,
        data,
      });

      return result.data;
    } catch (err) {
      if (err.response.status === 400)
        alert(`You can not donate more then is available!`);
    }
  }

  const providerValue = {
    getData,
    patchOffset,
    projects,
  };

  return (
    <NGOContext.Provider value={providerValue}>
      {props.children}
    </NGOContext.Provider>
  );
};

export default NGOContext;
