import React, { useContext, useState, useCallback, useEffect } from "react";

import { DataGrid } from "@mui/x-data-grid";

import NGOContext from "../../contexts/NGOContext";

import "../../scss/App.scss";

export default function NGODataGrid() {
  const { getData, patchOffset, projects } = useContext(NGOContext);

  const [inputFieldHidden, setInputFieldHidden] = useState(true);
  const [offset, setOffset] = useState("");
  const [donationProjectId, setDonationProjectId] = useState("");
  const [offsetData, setOffsetData] = useState({
    donationProjectId: "",
    amount: "",
  });

  const handleOffset = useCallback(async () => {
    const result = await patchOffset(offsetData);
    if (result !== undefined) {
      alert(`You've successfully donated this amount!`);

      getData();
    }
    return result;
  }, [patchOffset, offsetData, getData]);

  const handleRowClick = (row) => {
    setDonationProjectId(row.id);
    setOffsetData({
      donationProjectId: row.id,
      amount: offset,
    });
    setInputFieldHidden(false);
  };

  const handleDisableOffset = () => {
    if (!offset || offset === "0") return true;

    return false;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleOffset();
  };

  const handleChangeOffset = (event) => {
    event.preventDefault();
    setOffset(event.target.value);
    setOffsetData({
      amount: event.target.value,
      donationProjectId: donationProjectId,
    });
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const columns = [
    { field: "id", hide: true, filterable: false },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      width: 325,
      sortable: false,
    },
    {
      field: "type",
      headerName: "Type",
      headerClassName: "super-app-theme--header",
      width: 343,
      sortable: false,
    },
    {
      field: "totalAmountDonated",
      headerName: "Total amount donated",
      headerClassName: "super-app-theme--header",
      width: 320,
      sortable: false,
    },
  ];

  return (
    <React.Fragment>
      <div className="ngo-data-grid">
        <DataGrid
          rows={projects}
          columns={columns}
          pageSize={14}
          rowsPerPageOptions={[14]}
          disableColumnSelector
          onRowClick={(ban) => handleRowClick(ban)}
        />
        <div className="offset-input">
          <div hidden={!inputFieldHidden}>
            <h2>Select the project to enter offset</h2>
          </div>
          <br />
          <div hidden={inputFieldHidden}>
            <form onSubmit={handleSubmit}>
              <label>
                Offset amount:
                <input
                  min="0"
                  type="number"
                  value={offset}
                  onChange={handleChangeOffset}
                  placeholder="Enter offset"
                />
              </label>
              <input
                type="submit"
                value="Send"
                disabled={handleDisableOffset()}
              />
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
