import React, { useState, useEffect, useContext, useCallback } from "react";
import InputField from "../../components/InputField";
import ButtonField from "../../components/ButtonField";
import BansContext from "../../contexts/BansContext";

export default function UserUnbanModal({ user }) {

  const { confirmUnbanUser, unbanUser } = useContext(BansContext);
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [hiddenBan] = useState(false);
  const [userId, setUserId] = useState(user.id);
  const [confirmUnbanData, setConfirmUnbanData] = useState({
      code: "",
      password: "",
  });

  useEffect(() => {
    setUserId(user.id);
  }, [user]);

  
  const handleConfirmUnbanUser = useCallback(async () => {
    const result = await confirmUnbanUser(userId, confirmUnbanData);
    if(result?.status === 200){
      alert(`You have successfully unbanned ${user.nickname}!`);
      window.location= "/users"
    }
    
    return result;
  }, [confirmUnbanUser, userId,confirmUnbanData, user.nickname]);

  const requestUnbanUser = useCallback(async () => {
    const result = await unbanUser(userId);
    return result;
  }, [unbanUser, userId]);

  if(!user)
  return <div></div>

  const handleSubmit = (event) => {
    event.preventDefault();
    handleConfirmUnbanUser()
  };

  const handleResendMail = () => {
    requestUnbanUser();
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
    setConfirmUnbanData({
      password: event.target.value,
      code: code,
    })
  };
  const handleChangeCode = (event) => {
    event.preventDefault();
    setCode(event.target.value);
    setConfirmUnbanData({
      code: event.target.value,
      password: password,
    })
  };

  const handleDisableConfirmUnban = () => {
    if (!code || !password) return true;
    else return false;
  };

  return (
    <div>
      <h2>Unban user</h2> 
      <form onSubmit={handleSubmit}>
        <h3>We've sent a code to your email. Please enter it down below.  </h3> 
        <ButtonField
            onClick={handleResendMail}
            name="Send mail again"
        />
        <InputField
            type="text"
            value={code}
            onChange={handleChangeCode}
            placeholder="Enter a code"
            labelName="Code"
        />
        <label>
          Password:
          <input
              type="password"
              value={password}
              onChange={handleChangePassword}
              placeholder="Enter a password"
          />
        </label>
        <input hidden={hiddenBan} type="submit" value="Unban" disabled={handleDisableConfirmUnban()}/>
      </form>
    </div>
  );
}
