import React, { useContext } from "react";

import { Link } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { MenuItem } from "@mui/material";

import AdminContext from "../contexts/AdminContext";

export default function Sidebar() {
  const { loggedAdmin } = useContext(AdminContext);

  return (
    <div>
      <List>
        {[
          { name: "User management", path: "users" },
          { name: "Trade management", path: "buy-requests" },
          { name: "Ad management", path: "ads" },
          { name: "Report management", path: "reports" },
          { name: "Ban management", path: "bans" },
          { name: "NGO management", path: "ngo" },
          { name: "Admin management", path: "admin" },
          { name: "Profile", path: "profile" },
        ].map((item) => (
          <ListItem button key={item.path}>
            <div hidden={item.path === "admin" && loggedAdmin?.type === 1}>
              <KeyboardArrowRightIcon />
            </div>
            <div hidden={item.path === "admin" && loggedAdmin?.type === 1}>
              <MenuItem component={Link} to={`/${item.path}`}>
                {item.name.toUpperCase()}
              </MenuItem>
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
