import React, { useState, createContext, useContext, useCallback } from "react";

import AxiosContext from "./AxiosContext";

const BansContext = createContext();

export const BansProvider = (props) => {
  const { axiosInstance } = useContext(AxiosContext);

  const [bans, setBans] = useState([]);
  const [deletedBans, setDeletedBans] = useState([]);

  async function confirmBanUser(id, data) {
    try {
      const result = await axiosInstance({
        method: "PATCH",
        url: `/bans/confirm-ban/${id}`,
        data,
      });

      return result;
    } catch (err) {
      err.response.data.errors.error
        ? alert(err.response.data.errors.error)
        : alert(err.response.data.errors.Code[0]);
    }
  }

  const getBans = useCallback(async () => {
    const result = await axiosInstance({
      method: "GET",
      url: "/bans",
    });
    const filterArray = result.data?.filter((ban) => ban.type === 1);

    return setBans(filterArray);
  }, [axiosInstance]);

  const getDeletedBans = useCallback(async () => {
    const result = await axiosInstance({
      method: "GET",
      url: "/bans",
    });
    const filterArray = result.data?.filter((ban) => ban.type === 0);
    filterArray?.reverse();

    return setDeletedBans(filterArray);
  }, [axiosInstance]);

  async function confirmUnbanUser(id, data) {
    try {
      const result = await axiosInstance({
        method: "PATCH",
        url: `/bans/unban/${id}`,
        data,
      });

      return result;
    } catch (err) {
      err.response.data.errors.error
        ? alert(err.response.data.errors.error)
        : alert(err.response.data.errors.Code[0]);
    }
  }

  async function banUser(data) {
    try {
      const result = await axiosInstance({
        method: "POST",
        url: `/bans`,
        data,
      });

      return result.data;
    } catch (err) {
      if (err.response.status === 400)
        alert(`This user is already in ban process, go to ban management!`);
    }
  }

  async function unbanUser(id) {
    const result = await axiosInstance({
      method: "GET",
      url: `/bans/request-unban/${id}`,
    });

    return result.data;
  }

  async function getOneBan(id) {
    const result = await axiosInstance({
      method: "GET",
      url: `/bans/${id}`,
    });

    return result.data;
  }

  async function resendMail(id) {
    const result = await axiosInstance({
      method: "PATCH",
      url: `/bans/resend-email-verification/${id}`,
    });

    return result.data;
  }

  const providerValue = {
    banUser,
    confirmBanUser,
    unbanUser,
    confirmUnbanUser,
    resendMail,
    getOneBan,
    getBans,
    bans,
    getDeletedBans,
    deletedBans,
  };

  return (
    <BansContext.Provider value={providerValue}>
      {props.children}
    </BansContext.Provider>
  );
};

export default BansContext;
