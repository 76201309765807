import React from "react";

import { AdsProvider } from "./AdsContext";
import { AuthenticationProvider } from "./AuthenticationContext";
import { AxiosProvider } from "./AxiosContext";
import { TradesProvider } from "./TradesContext";
import { UsersProvider } from "./UsersContext";
import { LoginProvider } from "./LoginContext";
import { ErrorProvider } from "./ErrorContext";
import { BansProvider } from "./BansContext";
import { NGOProvider } from "./NGOContext";
import { AdminProvider } from "./AdminContext";
import { ReportsProvider } from "./ReportContext";

const GlobalContextProvider = (props) => {
  return (
    <AuthenticationProvider>
      <AxiosProvider>
        <ErrorProvider>
          <LoginProvider>
            <UsersProvider>
              <AdsProvider>
                <BansProvider>
                  <NGOProvider>
                    <AdminProvider>
                      <ReportsProvider>
                        <TradesProvider>{props.children}</TradesProvider>
                      </ReportsProvider>
                    </AdminProvider>
                  </NGOProvider>
                </BansProvider>
              </AdsProvider>
            </UsersProvider>
          </LoginProvider>
        </ErrorProvider>
      </AxiosProvider>
    </AuthenticationProvider>
  );
};

export default GlobalContextProvider;
