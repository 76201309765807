import React, { createContext, useContext } from "react";

import AuthenticationContext from "./AuthenticationContext";
import AxiosContext from "./AxiosContext";
import ErrorContext from "./ErrorContext";

const LoginContext = createContext();

export const LoginProvider = (props) => {
  const { saveUserToken, removeToken } = useContext(AuthenticationContext);
  const { axiosInstance } = useContext(AxiosContext);
  const { setError } = useContext(ErrorContext);

  async function authenticate(data) {
    try {
      const result = await axiosInstance({
        method: "POST",
        url: "/admins/sign-in",
        data,
      });

      saveUserToken(result.data.token);
      window.location.reload();
    } catch (err) {
      setError(err);
      removeToken();
    }
  }

  const providerValue = {
    authenticate,
  };

  return (
    <LoginContext.Provider value={providerValue}>
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginContext;
