import React, { useContext, useEffect, useState, useCallback } from "react";

import { useParams } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";

import TradesContext from "../../contexts/TradesContext";

import {
  handleFuelType,
  handleFuelStandard,
  handleDate,
  handleStatusOfTrade,
} from "../../utils/FunctionsUtil";

import "../../scss/App.scss";

export default function UserTradesDataGrid() {
  const { getTradesByUserBuyer, getTradesByUserSeller } =
    useContext(TradesContext);

  const [tradesForUserSeller, setTradesForUserSeller] = useState();
  const [tradesForUserBuyer, setTradesForUserBuyer] = useState();

  let { id } = useParams();

  const initializeTradesForUserBuyer = useCallback(async () => {
    const resultsForBuyer = await getTradesByUserBuyer(id);

    return setTradesForUserBuyer(resultsForBuyer);
  }, [getTradesByUserBuyer, id]);

  const initializeTradesForUserSeller = useCallback(async () => {
    const resultsForSeller = await getTradesByUserSeller(id);

    return setTradesForUserSeller(resultsForSeller);
  }, [getTradesByUserSeller, id]);

  useEffect(() => {
    initializeTradesForUserBuyer();
  }, [initializeTradesForUserBuyer]);

  useEffect(() => {
    initializeTradesForUserSeller();
  }, [initializeTradesForUserSeller]);

  const getRows = () => {
    if (tradesForUserSeller && tradesForUserBuyer)
      return tradesForUserSeller.concat(tradesForUserBuyer);
    else return [];
  };

  const columns = [
    { field: "id", hide: true, filterable: false },
    {
      field: "priceByLiter",
      headerName: "Price per liter",
      headerClassName: "super-app-theme--header",
      width: 200,
      filterable: false,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerClassName: "super-app-theme--header",
      width: 200,
      filterable: false,
    },
    {
      field: "fuelType",
      headerName: "Fuel type",
      headerClassName: "super-app-theme--header",
      width: 200,
      filterable: false,
      valueGetter: (params) => handleFuelType(params.value),
    },
    {
      field: "fuelStandard",
      headerName: "Fuel standard",
      headerClassName: "super-app-theme--header",
      width: 210,
      filterable: false,
      valueGetter: (params) => handleFuelStandard(params.value),
    },
    {
      field: "status",
      headerName: "Status of trade",
      headerClassName: "super-app-theme--header",
      width: 270,
      filterable: false,
      valueGetter: (params) => handleStatusOfTrade(params.value),
    },
    {
      field: "statusOfUser",
      headerName: "Status of user",
      headerClassName: "super-app-theme--header",
      width: 230,
      filterable: false,
      valueGetter: (params) => {
        if (params.row.buyerId === parseInt(id)) return "Buyer";
        else return "Seller";
      },
    },
    {
      field: "location #1 (country/city)",
      headerName: "Location",
      headerClassName: "super-app-theme--header",
      width: 230,
      filterable: false,
      valueGetter: (params) => params.row.country + ", " + params.row.city,
    },
    {
      field: "createdAt",
      headerName: "Creation date",
      headerClassName: "super-app-theme--header",
      width: 250,
      type: "date",
      filterable: false,
      valueGetter: (params) => handleDate(params.value),
    },
  ];

  return (
    <div className="user-trades-data-grid">
      <h3 className="user-trades-header">User trades</h3>
      <DataGrid
        rows={getRows()}
        columns={columns}
        pageSize={11}
        rowsPerPageOptions={[11]}
        disableColumnSelector
      />
    </div>
  );
}
