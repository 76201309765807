import React, { createContext, useCallback, useContext, useState } from "react";

import AxiosContext from "./AxiosContext";

const TradesContext = createContext();

export const TradesProvider = (props) => {
  const { axiosInstance } = useContext(AxiosContext);

  const [trades, setTrades] = useState([]);

  const getAllTrades = useCallback(async () => {
    const result = await axiosInstance({
      method: "GET",
      url: "/buy-requests/filter",
    });

    return setTrades(result.data);
  }, [axiosInstance]);

  async function getOneTrade(id) {
    const result = await axiosInstance({
      method: "GET",
      url: `/buy-requests/singular/${id}`,
    });

    return result.data;
  }

  async function getTradesByUserBuyer(id) {
    const result = await axiosInstance({
      method: "GET",
      url: `/buy-requests/for-buyer/${id}`,
    });

    return result.data;
  }

  async function getTradesByUserSeller(id) {
    const result = await axiosInstance({
      method: "GET",
      url: `/buy-requests/for-seller/${id}`,
    });

    return result.data;
  }

  const providerValue = {
    getAllTrades,
    getTradesByUserBuyer,
    getTradesByUserSeller,
    getOneTrade,
    trades,
  };

  return (
    <TradesContext.Provider value={providerValue}>
      {props.children}
    </TradesContext.Provider>
  );
};

export default TradesContext;
