import React, { useCallback, useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router";

import TradesContext from "../../contexts/TradesContext";
import InputField from "../../components/InputField";
import ButtonField from "../../components/ButtonField";

import {
  handleFuelType,
  handleFuelStandard,
  handleStatusOfTrade,
  handleDate,
  handlePaymentType,
} from "../../utils/FunctionsUtil";

export default function TradePage() {
  const { getOneTrade } = useContext(TradesContext);
  const [trade, setTrade] = useState();
  const { id } = useParams();
  const history = useHistory();

  const initializeTrade = useCallback(async () => {
    const result = await getOneTrade(id);
    return setTrade(result);
  }, [id, getOneTrade]);

  useEffect(() => {
    initializeTrade();
  }, [initializeTrade]);

  const handlePayment = (param) => {
    if (trade) return handlePaymentType(param);
  };

  const buyerProfileClickHandler = () => {
    if (trade)
      history.push(`/users/${trade.buyer.id}`);
  }

  const sellerProfileClickHandler = () => {
    if (trade)
      history.push(`/users/${trade.seller.id}`);
  }

  return (
    <React.Fragment>
      <div className="page-details">
        <div className="page-details-first-part">
          <InputField
            type="text"
            id="buyer"
            value={trade?.buyer.nickname}
            labelName="Buyer:"
            disabled={true}
          />
          <ButtonField
            name="buyer profile"
            onClick={buyerProfileClickHandler}
          />
          <InputField
            type="text"
            id="seller"
            value={trade?.seller.nickname}
            labelName="Seller:"
            disabled={true}
          />
          <ButtonField
            name="seller profile"
            onClick={sellerProfileClickHandler}
          />
          <InputField
            type="text"
            id="ad"
            value={trade?.ad.id}
            labelName="Ad:"
            disabled={true}
          />
          <InputField
            type="text"
            id="price"
            value={trade?.priceByLiter}
            labelName="Price:"
            disabled={true}
          />
          <InputField
            type="text"
            id="quantity"
            value={trade?.quantity}
            labelName="Quantity:"
            disabled={true}
          />
          <InputField
            type="text"
            id="fuelType"
            value={handleFuelType(trade?.fuelType)}
            labelName="Fuel type:"
            disabled={true}
          />
          <InputField
            type="text"
            id="fuelStandard"
            value={handleFuelStandard(trade?.fuelStandard)}
            labelName="Fuel standard:"
            disabled={true}
          />
          <InputField
            type="text"
            id="status"
            value={handleStatusOfTrade(trade?.status)}
            labelName="Status:"
            disabled={true}
          />
        </div>
        <div className="page-details-second-part">
          <InputField
            type="text"
            id="paymentMethod"
            value={handlePayment(trade?.paymentMethod)}
            disabled={true}
            labelName="Payment method:"
          />
          <InputField
            type="text"
            id="creationDate"
            value={handleDate(trade?.createdAt)}
            disabled={true}
            labelName="Creation date:"
          />
          <InputField
            type="text"
            id="meetingDate"
            value={handleDate(trade?.meetingDateTime)}
            disabled={true}
            labelName="Meeting Date:"
          />
          <InputField
            type="text"
            id="location"
            value={trade?.country + ", " + trade?.city}
            disabled={true}
            labelName="Location:"
          />
        </div>
      </div>
    </React.Fragment>
  );
}
