import React, { createContext, useContext, useState, useCallback } from "react";

import AxiosContext from "./AxiosContext";

const AdsContext = createContext();

export const AdsProvider = (props) => {
  const { axiosInstance } = useContext(AxiosContext);

  const [ads, setAds] = useState([]);

  const getAllAds = useCallback(async () => {
    const result = await axiosInstance({
      method: "GET",
      url: `/ads/filter-ad-management`,
    });

    return setAds(result.data);
  }, [axiosInstance]);

  async function getOneAd(id) {
    const result = await axiosInstance({
      method: "GET",
      url: `/ads/singular/${id}`,
    });

    return result.data;
  }

  async function getAdsByUser(id) {
    const result = await axiosInstance({
      method: "GET",
      url: `ads/by-seller/${id}`,
    });

    return result.data;
  }

  const providerValue = {
    getAllAds,
    getAdsByUser,
    ads,
    getOneAd,
  };

  return (
    <AdsContext.Provider value={providerValue}>
      {props.children}
    </AdsContext.Provider>
  );
};

export default AdsContext;
