import React, { useState, useEffect, useContext, useCallback } from "react";
import InputField from "../../components/InputField";
import BansContext from "../../contexts/BansContext";
import ButtonField from "../../components/ButtonField";

export default function UserBanModal({ user }) {

  const { banUser, confirmBanUser, resendMail } = useContext(BansContext);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [days, setDays] = useState(1);
  const [confirmHide, setConfirmHide] = useState(true);
  const [disableBanDays, setDisableBanDays] = useState(true);
  const [confirmBanData, setConfirmBanData] = useState({
      code: "",
      password: "",
  });
  const [hiddenUnban, setHiddenBan] = useState(false);
  const [banId, setBanId] = useState("");
  const [ban, setBan] = useState({
    title: "",
    Description: "",
    Type: 0,
    Days: null,
    CountryCode: user?.countryCode,
    AreaCode: user?.areaCode,
    PhoneNumber: user?.phoneNumber,
    UserId: user?.id
  });

  useEffect(() => {
    setBan(prevState => ({
      ...prevState,
      Type: 0,
      Days: null,
      CountryCode: user?.countryCode,
      AreaCode: user?.areaCode,
      PhoneNumber: user?.phoneNumber,
      UserId: user?.id
    }));
  }, [user]);
  
  const handleBanUser = useCallback(async () => {
    const result = await banUser(ban);
    if(result?.id == null)
      window.location= "/users"
    setBanId(result?.id)
    return result;
  }, [banUser, ban]);

  const handleBanUserConfirm = useCallback(async () => {
    const result = await  confirmBanUser(banId, confirmBanData)
    if(result?.status === 200){
      alert(`You have successfully banned ${user.nickname}!`);
      window.location= "/users"
    }

    return result;
  }, [confirmBanUser, banId, confirmBanData, user.nickname]);


  if(!user)
  return <div></div>

  const handleSubmitBan = (event) => {
    event.preventDefault();
    handleBanUserConfirm();
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setConfirmHide(false);
    setHiddenBan(true);
    handleBanUser();
  };

  const handleChangeTitle = (event) => {
    event.preventDefault();
    setTitle(event.target.value);
    setBan(prevState => ({
      ...prevState,
      title: event.target.value,
    }));
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
    setConfirmBanData({
      password: event.target.value,
      code: code,
    })
  };
  const handleChangeCode = (event) => {
    event.preventDefault();
    setCode(event.target.value);
    setConfirmBanData({
      code: event.target.value,
      password: password,
    });
  };


  const handleChangeText = (event) => {
    event.preventDefault();
    setText(event.target.value);
    setBan(prevState => ({
      ...prevState,
      Description: event.target.value,
    }));
  };
  
  const handleChangeNumber = (event) => {
    event.preventDefault();
    setDays(event.target.value);
    setBan(prevState => ({
      ...prevState,
      Type: 1,
      Days: event.target.value,
    }));
  };

  const handleDisableBan = () => {
    if (!title || !text) return true;
    else return false;
  };

  const handleResendMail = () => {
    resendMail(banId);
  };

  const handleBanType = () => {
    var checkBox = document.getElementById("myCheck");

    if (checkBox.checked){
      setDisableBanDays(true);
      setBan(prevState => ({
        ...prevState,
        Type: 0,
        Days: null,
      }));
      setDays("");
    } else {
      setDisableBanDays(false);
    }
  };

  const handleDisableConfirmBan = () => {
    if (!code || !password) return true;
    else return false;
  };

  return (
    <div>
      <div hidden={hiddenUnban}>
        <h3>Ban user</h3>
        <form onSubmit={handleSubmit}>
          <InputField
            type="text"
            value={title}
            onChange={handleChangeTitle}
            placeholder="Enter a title"
            labelName="Title"
          />
          <label>
            Description:
            <input
              type="text"
              value={text}
              onChange={handleChangeText}
              placeholder="Enter a text"
            />
          </label>
          <br/><br/>
          <input defaultChecked type="checkbox" text="Permanent" id="myCheck" onChange={handleBanType}/>
          <label>Permanent ban</label><br/><br/>
          <label>
            Enter ban days: (up to 30 days)
            <input
              disabled={disableBanDays}
              type="number"
              min="1"
              max="30"
              value={days}
              onChange={handleChangeNumber}
              placeholder="Enter a number"
            />
          </label>
          <input type="submit" value="Ban" disabled={handleDisableBan()}/>
        </form>
      </div>
      <div hidden={confirmHide}>
        <h3>Confirm </h3>
        <form onSubmit={handleSubmitBan}>
          <h3>We've sent a code to your email. Please enter it down below.  </h3> 
          <ButtonField
              onClick={handleResendMail}
              name="Send mail again"
          />
          <InputField
            type="text"
            value={code}
            onChange={handleChangeCode}
            placeholder="Enter a code"
            labelName="Code"
          />
          <label>
            Password:
            <input
              type="password"
              value={password}
              onChange={handleChangePassword}
              placeholder="Enter a password"
            />
          </label>
          <input type="submit" value="Ban" disabled={handleDisableConfirmBan()}/>
        </form>
      </div>
    </div>
  );
}
