import React, { useState, createContext, useContext, useCallback } from "react";

import AxiosContext from "./AxiosContext";

const ReportContext = createContext();

export const ReportsProvider = (props) => {
  const { axiosInstance } = useContext(AxiosContext);

  const [reports, setReports] = useState([]);
  const [unsolvedReports, setUnsolvedReports] = useState([]);

  const getReports = useCallback(async () => {
    const result = await axiosInstance({
      method: "GET",
      url: "/reports",
    });
    const filterArray = result.data.filter(
      (report) => report.isResolved === true
    );

    return setReports(filterArray);
  }, [axiosInstance]);

  const getUnsolvedReports = useCallback(async () => {
    const result = await axiosInstance({
      method: "GET",
      url: "/reports",
    });
    const filterArray = result.data.filter(
      (report) => report.isResolved === false
    );
    filterArray.reverse();

    return setUnsolvedReports(filterArray);
  }, [axiosInstance]);

  async function getOneReport(id) {
    const result = await axiosInstance({
      method: "GET",
      url: `/reports?Id=${id}`,
    });

    return result.data;
  }

  async function resolveReport(id, data) {
    try {
      const result = await axiosInstance({
        method: "PATCH",
        url: `/reports/${id}`,
        data,
      });
      getReports();
      getUnsolvedReports();

      return result.data;
    } catch (err) {
      alert(err.response.data.errors.error);
    }
  }

  const providerValue = {
    getReports,
    getUnsolvedReports,
    getOneReport,
    reports,
    unsolvedReports,
    resolveReport,
  };

  return (
    <ReportContext.Provider value={providerValue}>
      {props.children}
    </ReportContext.Provider>
  );
};

export default ReportContext;
