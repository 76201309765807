export default function TextArea(props) {
    const { labelName, value, disabled } = props;

    const divStyles = {
        display: 'flex',
        flexDirection: 'column'
    }

    return (
        <div style={divStyles}>
            <label style={{marginBottom: '8px'}}>{labelName}</label>
            <textarea
                style={{
                    resize: 'none'
                }}
                disabled={disabled}
                rows="15"
                cols="4"
            >
                {value}
            </textarea>
        </div>
    )
}