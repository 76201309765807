import React, { useContext, useEffect } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { useHistory } from "react-router";

import TradesContext from "../../contexts/TradesContext";

import { columnsAllTrade } from "../../pages/trades/TradeDataInfo";

import "../../scss/App.scss";

export default function DataTable(props) {
  const { trades, getAllTrades } = useContext(TradesContext);
  const history = useHistory();

  const { headerStyle, headerText } = props;

  useEffect(() => {
    getAllTrades();
  }, [getAllTrades]);

  return (
    <React.Fragment>
      <div className="main-data-grid">
        <h3 className={headerStyle}>{headerText}</h3>
        <DataGrid
          onRowClick={(trade) => history.push(`/buy-requests/${trade.id}`)}
          rows={trades}
          columns={columnsAllTrade}
          pageSize={14}
          rowsPerPageOptions={[14]}
          disableColumnSelector
        />
      </div>
    </React.Fragment>
  );
}
