import React, { useState, useEffect, useContext, useCallback } from "react";

import InputField from "../../components/InputField";
import BansContext from "../../contexts/BansContext";

export default function ConfirmBanModal({ submit, id }) {

  const { confirmBanUser } = useContext(BansContext);
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [hiddenBan] = useState(false);
  const [banId, setBanId] = useState(id);
  const [confirmBanData, setConfirmBanData] = useState({
      code: "",
      password: "",
  });
  useEffect(() => {
    setBanId(id);
  }, [id]);

  
  const handleBanUserConfirm = useCallback(async () => {
    const result = await  confirmBanUser(banId, confirmBanData)
      alert(`You have successfully banned this user!`);
      window.location= "/bans"
    
    return result;
  }, [confirmBanUser, banId, confirmBanData]);

  
  if(!id)
  return <div></div>

  const handleSubmit = (event) => {
    event.preventDefault();
    handleBanUserConfirm()
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
    setConfirmBanData({
      password: event.target.value,
      code: code,
    })
  };
  const handleChangeCode = (event) => {
    event.preventDefault();
    setCode(event.target.value);
    setConfirmBanData({
      code: event.target.value,
      password: password,
    })
  };

  const handleDisableConfirmBan = () => {
    if (!code || !password) return true;
    else return false;
  };

  return (
    <div>
      <h2>Unban user</h2> 
      <h3>We've sent a code to your email. Please enter it down below.  </h3> 
        <form onSubmit={handleSubmit}>
          <InputField
            type="text"
            value={code}
            onChange={handleChangeCode}
            placeholder="Enter a code"
            labelName="Code"
          />
          <label>
            Password:
            <input
                type="password"
                value={password}
                onChange={handleChangePassword}
                placeholder="Enter a password"
            />
          </label>
          <input hidden={hiddenBan} type="submit" value="Ban" disabled={handleDisableConfirmBan()}/>
        </form>
    </div>
  );
}
