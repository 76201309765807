import React, { useState, useContext } from "react";

import { useHistory } from "react-router";

import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

import AuthenticationContext from "../contexts/AuthenticationContext";
import AdminContext from "../contexts/AdminContext";

import Sidebar from "./Sidebar";

const drawerWidth = 280;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  navbar: {
    justifyContent: "space-between",
    backgroundColor: "#233948",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  drawerContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function Navbar() {
  const { removeToken } = useContext(AuthenticationContext);
  const { loggedAdmin } = useContext(AdminContext);

  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const showSidebar = () => setOpen(!open);

  const handleLogout = () => {
    removeToken();
    history.push("/");
    window.location.reload();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.navbar}>
          <div className={classes.drawerContainer}>
            {loggedAdmin?.isEmailVerified && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={showSidebar}
                edge="start"
                className={clsx(open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6" noWrap>
              Allexx App - Back Office
            </Typography>
          </div>
          <IconButton color="inherit" onClick={handleLogout}>
            <Typography variant="h6" noWrap>
              Log out
            </Typography>
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={showSidebar}>
            {theme.direction === "ltr" && <ChevronLeftIcon />}
            {theme.direction !== "ltr" && <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        {loggedAdmin?.isEmailVerified && <Sidebar />}
      </Drawer>
    </div>
  );
}
