import React, { useContext, useEffect } from "react";

import { useHistory } from "react-router";
import { DataGrid } from "@mui/x-data-grid";

import UsersContext from "../../contexts/UsersContext";

import { handleDate } from "../../utils/FunctionsUtil";

import "../../scss/App.scss";

export default function DataTable() {
  const { users, getData } = useContext(UsersContext);
  const history = useHistory();

  useEffect(() => {
    getData();
  }, [getData]);


  const columns = [
    { field: "id", hide: true, filterable: false },
    {
      field: "isBanned",
      headerName: "Banned",
      hide: true,
      type: "boolean",
    },
    {
      field: "nickname",
      headerName: "Username",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      width: 230,
      sortable: false,
    },
    {
      field: "firstName",
      headerName: "First name",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => params.value.trim()
    },
    {
      field: "lastName",
      headerName: "Last name",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => params.value.trim()
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "left",
      type: "number",
      width: 160,
      sortable: false,
      valueGetter: (params) => params.row.areaCode + params.row.phoneNumber,
    },
    {
      field: "country",
      headerName: "Country",
      headerClassName: "super-app-theme--header",
      width: 150,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Creation date",
      headerClassName: "super-app-theme--header",
      type: "date",
      width: 170,
      filterable: false,
      valueFormatter: (params) => handleDate(params.value),
      valueGetter: (params) => params.value
    },
    {
      field: "completedTradesAsBuyer",
      headerName: "Completed trades - buyer",
      headerClassName: "super-app-theme--header",
      type: "number",
      width: 250,
      filterable: false,
    },
    {
      field: "completedTradesAsSeller",
      headerName: "Completed trades - seller",
      headerClassName: "super-app-theme--header",
      type: "number",
      width: 250,
      filterable: false,
    },
    {
      field: "numberOfTrades",
      headerName: "Completed trades - all",
      headerClassName: "super-app-theme--header",
      type: "number",
      width: 250,
      filterable: false,
      valueGetter: (params) =>
        parseInt(params.row.completedTradesAsBuyer) +
        parseInt(params.row.completedTradesAsSeller),
    },
  ];

  return (
    <React.Fragment>
      <div className="main-data-grid">
        <DataGrid
          onRowClick={(user) => history.push(`/users/${user.id}`)}
          rows={users}
          columns={columns}
          pageSize={14}
          rowsPerPageOptions={[14]}
          disableColumnSelector
        />
      </div>
    </React.Fragment>
  );
}
