import React, { useContext, useEffect } from "react";

import { useHistory } from "react-router";
import { DataGrid } from "@mui/x-data-grid";

import AdminContext from "../../contexts/AdminContext";
import AuthenticationContext from "../../contexts/AuthenticationContext";

import { handleType } from "../../utils/FunctionsUtil";

import "../../scss/App.scss";

export default function AdminDataGrid() {
  const { admins, loggedAdmin, getAll } = useContext(AdminContext);
  const { token } = useContext(AuthenticationContext);

  const history = useHistory();

  const handleCreateAdmin = () => {
    history.push("/create-admin");
  };

  useEffect(() => {
    token && getAll();
  }, [getAll, token]);

  const columns = [
    { field: "id", hide: true, filterable: false },
    {
      field: "username",
      headerName: "Username",
      headerClassName: "super-app-theme--header",
      width: 442,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      width: 450,
      sortable: false,
    },
    {
      field: "type",
      headerName: "Type",
      headerClassName: "super-app-theme--header",
      width: 450,
      sortable: false,
      valueGetter: (params) => handleType(params.value),
    },
  ];

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <div style={{ width: "70%" }} className="main-data-grid">
          <DataGrid
            onRowClick={(admin) => history.push(`/admin/${admin.id}`)}
            rows={admins}
            columns={columns}
            pageSize={14}
            rowsPerPageOptions={[14]}
            disableColumnSelector
          />
        </div>
        <div>
          <button
            hidden={loggedAdmin?.type === 1}
            onClick={handleCreateAdmin}
            style={{
              cursor: "pointer",
              marginTop: "150px",
              marginLeft: "100px",
              width: "200px",
              height: "50px",
            }}
            className="resolve-button"
          >
            {" "}
            Create new admin{" "}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
