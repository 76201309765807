import { useContext, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Modal from "react-modal";

import AdminContext from "./contexts/AdminContext";
import AuthenticationContext from "./contexts/AuthenticationContext";

import UserDataGrid from "./pages/user/UserDataGrid";
import BanDataGrid from "./pages/bans/BanDataGrid";
import UserPage from "./pages/user/UserPage";
import BanPage from "./pages/bans/BanPage";
import TradeDataGrid from "./pages/trades/TradeDataGrid";
import TradePage from "./pages/trades/TradePage";
import AdDataGrid from "./pages/ads/AdDataGrid";
import AdPage from "./pages/ads/AdPage";
import NGODataGrid from "./pages/ngo/NGODataGrid";
import AdminPage from "./pages/admin/AdminPage";
import ReportDataGrid from "./pages/reports/ReportDataGrid";
import ReportPage from "./pages/reports/ReportPage";
import AdminDataGrid from "./pages/admin/AdminDataGrid";
import SingleAdminPage from "./pages/admin/SingleAdminPage";
import Profile from "./pages/admin/Profile";
import Navbar from "./components/Navbar";

import ProtectedRoute from "./components/ProtectedRoute";

Modal.setAppElement("#root");

function App() {
  const { loggedAdmin, handleGetLoggedAdmin } = useContext(AdminContext);
  const { token } = useContext(AuthenticationContext);

  useEffect(() => {
    token && handleGetLoggedAdmin();
  }, [handleGetLoggedAdmin, token]);

  return (
    <Router>
      {loggedAdmin && <Navbar />}
      <div>
        <ProtectedRoute
          exact
          path="/"
          component={loggedAdmin?.isEmailVerified ? UserDataGrid : Profile}
        />
        <ProtectedRoute exact path="/users" component={UserDataGrid} />
        <ProtectedRoute exact path="/bans" component={BanDataGrid} />
        <ProtectedRoute exact path="/ngo" component={NGODataGrid} />
        <ProtectedRoute exact path="/create-admin" component={AdminPage} />
        <ProtectedRoute exact path="/admin" component={AdminDataGrid} />
        <ProtectedRoute exact path="/profile" component={Profile} />
        <ProtectedRoute exact path="/admin/:id" component={SingleAdminPage} />
        <ProtectedRoute exact path="/buy-requests" component={TradeDataGrid} />
        <ProtectedRoute exact path="/users/:id" component={UserPage} />
        <ProtectedRoute exact path="/bans/:id" component={BanPage} />
        <ProtectedRoute exact path="/buy-requests/:id" component={TradePage} />
        <ProtectedRoute exact path="/ads" component={AdDataGrid} />
        <ProtectedRoute exact path="/ads/:id" component={AdPage} />
        <ProtectedRoute exact path="/reports/" component={ReportDataGrid} />
        <ProtectedRoute exact path="/reports/:id" component={ReportPage} />
      </div>
    </Router>
  );
}

export default App;
