import React, { useCallback, useContext, useEffect, useState } from "react";

import { useParams, useHistory } from "react-router-dom";

import InputField from "../../components/InputField";
import ButtonField from "../../components/ButtonField";
import ModalField from "../../components/ModalField";
import TradeDataGrid from "../trades/TradeDataGrid";

import { columnsAdTrades } from "../trades/TradeDataInfo";

import {
  handleFuelType,
  handleVehicleType,
  handlePaymentType,
  handleFuelStandard,
  handleTimeFrame,
  handleDate
} from "../../utils/FunctionsUtil";

import AdsContext from "../../contexts/AdsContext";

export default function AdPage() {
  const { getOneAd } = useContext(AdsContext);

  const [ad, setAd] = useState();
  const [openTradeModal, setOpenTradeModal] = useState();

  const { id } = useParams();
  const history = useHistory();

  const initializeAd = useCallback(async () => {
    const result = await getOneAd(id);
    return setAd(result);
  }, [getOneAd, id]);

  useEffect(() => {
    initializeAd();
  }, [initializeAd]);

  const handleTotalPrice = () => {
    if (ad) return (ad.currentPriceByLiter * ad.totalQuantity).toFixed(2);
  };

  const getRows = () => {
    if (ad) return ad.buyRequests;
    else return [];
  };

  const sellerProfileClickHandler = () => {
    if (ad)
      history.push(`/users/${ad.seller.id}`);
  }

  return (
    <React.Fragment>
      <div className="page-details">
        <div className="page-details-first-part">
          <InputField
            type="text"
            id="sellerNickname"
            value={ad?.seller.nickname}
            labelName="Seller username"
            disabled={true}
          ></InputField>
          <ButtonField
            name="seller profile"
            onClick={sellerProfileClickHandler}
          />
          <InputField
            type="text"
            id="priceByLiter"
            value={ad?.currentPriceByLiter}
            labelName="Price by liter"
            disabled={true}
          ></InputField>
          <InputField
            type="text"
            id="totalPrice"
            value={handleTotalPrice()}
            labelName="Total price"
            disabled={true}
          ></InputField>
          <InputField
            type="text"
            id="availableQuantity"
            value={ad?.availableQuantity}
            labelName="Available quantity"
            disabled={true}
          ></InputField>
          <InputField
            type="text"
            id="totalQuantity"
            value={ad?.totalQuantity}
            labelName="Total quantity"
            disabled={true}
          ></InputField>
          <InputField
            type="text"
            id="minimumSellingQuantity"
            value={ad?.minimumSellingQuantity}
            labelName="Minimum selling quantity"
            disabled={true}
          ></InputField>
          <InputField
            type="text"
            id="paymentType"
            value={handlePaymentType(ad?.paymentMethod)}
            labelName="Payment type"
            disabled={true}
          ></InputField>
        </div>

        <div className="page-details-second-part">
          <InputField
            type="text"
            id="fuelType"
            value={handleFuelType(ad?.fuelType)}
            labelName="Fuel type"
            disabled={true}
          ></InputField>
          <InputField
            type="text"
            id="fuelStandard"
            value={handleFuelStandard(ad?.fuelStandard)}
            labelName="Fuel standard"
            disabled={true}
          ></InputField>
          <InputField
            type="text"
            id="vehicleType"
            value={handleVehicleType(ad?.fueledFrom)}
            labelName="Vehicle type"
            disabled={true}
          ></InputField>
          <InputField
            type="text"
            id="location"
            value={ad?.locations[0]?.address}
            labelName="Location"
            disabled={true}
          ></InputField>
          <InputField
            type="text"
            id="creationDate"
            value={handleDate(ad?.createdAt)}
            labelName="Creation Date:"
            disabled={true}
          ></InputField>
          <InputField
            type="text"
            id="timeFrame"
            value={handleTimeFrame(ad?.locations[0]?.timeFrames)}
            labelName="Time frames"
            disabled={true}
          ></InputField>
        </div>

        <ModalField
          isOpen={openTradeModal}
          onClick={() => setOpenTradeModal(false)}
          component={
            <TradeDataGrid
              columns={columnsAdTrades}
              rows={getRows()}
              size={12}
              className="ad-trades-grid"
              headerStyle="ad-trades-header"
              headerText="Ad trades"
            />
          }
        ></ModalField>
      </div>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <ButtonField
          onClick={() => setOpenTradeModal(true)}
          name="List of trades"
        ></ButtonField>
      </div>
    </React.Fragment>
  );
}
