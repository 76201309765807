import React, { createContext, useContext, useState, useCallback } from "react";

import AxiosContext from "./AxiosContext";
import AuthenticationContext from "./AuthenticationContext";

const AdminContext = createContext();

export const AdminProvider = (props) => {
  const { axiosInstance } = useContext(AxiosContext);
  const { saveUserToken } = useContext(AuthenticationContext);

  const [admins, setAdmins] = useState([]);
  const [loggedAdmin, setAdmin] = useState();

  const getAll = useCallback(async () => {
    const result = await axiosInstance({
      method: "GET",
      url: `/admins`,
    });

    return setAdmins(result.data);
  }, [axiosInstance]);

  async function createAdmin(data) {
    try {
      const result = await axiosInstance({
        method: "POST",
        url: `/admins/register`,
        data,
      });
      getAll();

      return result.data;
    } catch (err) {
      alert(err.response.data.errors.error);
    }
  }

  async function deleteAdmin(id) {
    try {
      const result = await axiosInstance({
        method: "DELETE",
        url: `/admins/${id}`,
      });
      getAll();

      return result;
    } catch (err) {
      alert(err.response.data.errors.error);
    }
  }

  async function verifyAdmin(data) {
    try {
      const result = await axiosInstance({
        method: "PATCH",
        url: `/admins/verify-email`,
        data,
      });
      saveUserToken(result.data.token);

      return result;
    } catch (err) {
      err.response.data.errors.error
        ? alert(err.response.data.errors.error)
        : alert(err.response.data.errors.Code[0]);
    }
  }

  async function resendCode(data) {
    const result = await axiosInstance({
      method: "PATCH",
      url: `/admins/resend-email-verification`,
      data,
    });

    return result.data;
  }

  const handleGetLoggedAdmin = useCallback(async () => {
    const result = await axiosInstance({
      method: "GET",
      url: `/admins/get-data`,
    });

    return setAdmin(result.data);
  }, [axiosInstance]);

  async function getAdmin(id) {
    const result = await axiosInstance({
      method: "GET",
      url: `/admins/${id}`,
    });

    return result.data;
  }

  async function confirmDeleteUser(data) {
    try {
      const result = await axiosInstance({
        method: "DELETE",
        url: `/users/admin-delete`,
        data,
      });

      return result;
    } catch (err) {
      alert(err.response.data.errors.error);
    }
  }

  async function deleteUser(id) {
    const result = await axiosInstance({
      method: "POST",
      url: `/users/request-delete/${id}`,
    });

    return result.data;
  }

  async function updateAdmin(id, data) {
    await axiosInstance({
      method: "PATCH",
      url: `/admins/${id}`,
      data: data,
    });

    getAll();
  }

  const providerValue = {
    createAdmin,
    verifyAdmin,
    resendCode,
    admins,
    getAll,
    deleteAdmin,
    loggedAdmin,
    deleteUser,
    confirmDeleteUser,
    updateAdmin,
    getAdmin,
    handleGetLoggedAdmin,
  };

  return (
    <AdminContext.Provider value={providerValue}>
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminContext;
