import React, { useState, useContext, useCallback } from "react";
import InputField from "../../components/InputField";
import AdminContext from "../../contexts/AdminContext";
import ButtonField from "../../components/ButtonField";

export default function ConfirmAdminModal({ admin }) {
  const { verifyAdmin, resendCode } = useContext(AdminContext);

  const [code, setCode] = useState("");

  const [hiddenBan] = useState(false);

  const [confirmAdminData, setConfirmAdminData] = useState({
    Code: "",
  });

  const handleConfirmAdmin = useCallback(async () => {
    const result = await verifyAdmin(confirmAdminData);
    if (result?.status === 200) {
      alert(`You have successfully verified email!`);
      window.location = "/";
    }

    return result;
  }, [confirmAdminData, verifyAdmin]);

  if (!admin) return <div></div>;

  const handleSubmit = (event) => {
    event.preventDefault();
    handleConfirmAdmin();
  };

  const handleChangeCode = (event) => {
    event.preventDefault();
    setCode(event.target.value);
    setConfirmAdminData({
      ...confirmAdminData,
      Code: event.target.value,
    });
  };

  const handleDisableConfirm = () => {
    return !code;
  };

  const handleResendCode = () => {
    resendCode({ email: admin.email });
  };

  return (
    <div>
      <h2>Confirm admin registration</h2>
      <h3>We've sent a code to your email. Please enter it down below. </h3>
      <ButtonField onClick={handleResendCode} name="Send code again" />
      <form onSubmit={handleSubmit}>
        <InputField
          type="text"
          value={code}
          onChange={handleChangeCode}
          placeholder="Enter a code"
          labelName="Code"
        />
        <input
          hidden={hiddenBan}
          type="submit"
          value="Confirm"
          disabled={handleDisableConfirm()}
        />
      </form>
    </div>
  );
}
