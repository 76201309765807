import React, { useContext, useEffect } from "react";

import { useHistory } from "react-router";
import { DataGrid } from "@mui/x-data-grid";

import BansContext from "../../contexts/BansContext";
import AuthenticationContext from "../../contexts/AuthenticationContext";

import { handleDate, handleBanType } from "../../utils/FunctionsUtil";

import "../../scss/App.scss";

export default function BanDataGrid() {
  const { bans, deletedBans, getBans, getDeletedBans } =
    useContext(BansContext);
  const { token } = useContext(AuthenticationContext);

  const history = useHistory();

  useEffect(() => {
    token && getBans();
  }, [getBans, token]);

  useEffect(() => {
    token && getDeletedBans();
  }, [getDeletedBans, token]);

  const columns = [
    { field: "id", hide: true, filterable: false },
    {
      field: "nickname",
      headerName: "Username",
      headerClassName: "super-app-theme--header",
      width: 180,
      sortable: false,
      valueGetter: (params) => params.row.user.nickname,
    },
    {
      field: "days",
      headerName: "Ban time period",
      headerClassName: "super-app-theme--header",
      width: 190,
      sortable: false,
      valueGetter: (params) => params.value + " days",
    },
    {
      field: "createdAt",
      headerName: "Ban date",
      headerClassName: "super-app-theme--header",
      width: 220,
      valueGetter: (params) => handleDate(params.value),
    },
    {
      field: "title",
      headerName: "Title",
      headerClassName: "super-app-theme--header",
      width: 180,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 180,
      sortable: false,
      valueGetter: (params) => handleBanType(params.value),
    },
  ];

  const columnsDeleteUser = [
    { field: "id", hide: true, filterable: false },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      headerClassName: "super-app-theme--header",
      width: 230,
      sortable: false,
      valueGetter: (params) =>
        params.row.user.areaCode + params.row.user.phoneNumber,
    },
    {
      field: "createdAt",
      headerName: "Ban date",
      headerClassName: "super-app-theme--header",
      width: 240,
      sortable: false,
      valueGetter: (params) => handleDate(params.value),
    },
    {
      field: "title",
      headerName: "Title",
      headerClassName: "super-app-theme--header",
      width: 240,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 240,
      sortable: false,
      valueGetter: (params) => handleBanType(params.value),
    },
  ];

  return (
    <React.Fragment>
      <div className="header-title">
        <div><strong>Resolved</strong></div>
        <div><strong>Not Resolved</strong></div>
      </div>
      <div className="ban-data-grid" style={{ margin: 0, width: "100%" }}>
        <DataGrid
          onRowClick={(ban) => history.push(`/bans/${ban.id}`)}
          rows={bans}
          columns={columns}
          pageSize={14}
          rowsPerPageOptions={[14]}
          disableColumnSelector
        />
        <DataGrid
          onRowClick={(ban) => history.push(`/bans/${ban.id}`)}
          rows={deletedBans}
          columns={columnsDeleteUser}
          pageSize={14}
          rowsPerPageOptions={[14]}
          disableColumnSelector
        />
      </div>
    </React.Fragment>
  );
}
